<template>
  <div>
    <!-- 导航 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>角色管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容 -->
    <div class="content">
      <el-form inline label-position="right" label-width="60px" size="small" class="query-form">
        <el-form-item label="搜索">
          <el-input v-model="key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selList();">查询</el-button>
          <el-button type="success" @click="dialogVisible=true;form={isEnable:true};">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表查询 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>角色列表</span>
        </div>
        <!-- 列表定义 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width:100%;"
        >
          <el-table-column prop="name" label="角色名称"></el-table-column>
          <el-table-column prop="sortValue" label="排序"></el-table-column>
          <el-table-column prop="isSys" label="是否是系统角色">
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.isSys==true">是</el-tag>
              <el-tag type="warn" v-else>否</el-tag>
            </template>
          </el-table-column>

          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-tooltip content="设置权限" placement="top">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-s-tools"
                  @click="roleVisible=true; getRoleTree(scope.row.id);"
                ></el-button>
              </el-tooltip>
              <el-tooltip v-if="scope.row.isSys==false" content="编辑" placement="top">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-edit"
                  @click="editClick(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip v-if="scope.row.isSys==false" content="删除" placement="top">
                <el-button
                  type="warning"
                  size="mini"
                  icon="el-icon-delete"
                  @click="delClick(scope.row)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>

        <!-- 分页条 -->
        <div class="block" style="margin-top:20px;">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-sizes="[10, 20, 30, 50,100]"
            :page-size="pageSize"
            layout="total, sizes,prev, pager, next, jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>

    <!-- 操作对话框 -->
    <el-dialog :title="form.id>0?'编辑角色信息':'添加角色信息'" :visible.sync="dialogVisible">
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm('form')"
      >
        <el-form-item
          label="角色名称"
          prop="name"
          :rules="[
        {required:true,message:'请输入角色名称'}
        ]"
        >
          <el-input v-model="form.name" placeholder="请输入角色名称"></el-input>
        </el-form-item>

        <el-form-item
          label="排序角色"
          prop="sortValue"
          :rules="[{required:true,message:'不能为空'},{type:'number',message:'请填写数字'}]"
        >
          <el-input v-model.number="form.sortValue" placeholder="请输入排序角色"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit">提交</el-button>
          <el-button @click="dialogVisible=false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 设置权限对话框 -->
    <el-dialog title="设置角色的权限" :visible.sync="roleVisible">
      <el-tree
        ref="tree"
        :data="treeData"
        show-checkbox
        node-key="id"
        default-expand-all
        :default-checked-keys="checkedKeys"
      ></el-tree>

      <span slot="footer" class="dialog-footer">
        <el-button @click="roleVisible = false">取 消</el-button>
        <el-button type="primary" @click="resetRoleTree();">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";
export default {
  data() {
    return {
      key: "",
      query: {}, //对象
      loading: false,
      currentPage4: 1,
      pageSize: 10,
      total: 0,
      tableData: [],
      form: {}, //表单对象
      dialogVisible: false,
      submiting: false,
      id: 0,
      roleVisible: false, //是否显示角色对话框
      treeData: [], //权限树结构
      checkedKeys: [] //选中id列表
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageSize = val;
      this.currentPage4 = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
      this.getList();
    },
    //筛选
    filterTag(filter) {
      var val = filter["isEnable"][0];
      this.form.isEnable = val;
      //刷新列表
      this.getList();
    },
    //排序
    sortChange(sortColumn) {
      this.form.sort = sortColumn.prop;
      this.form.order = sortColumn.order;
      //刷新列表
      this.getList();
    },
    getList: function() {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageindex = _this.currentPage4;
      params.pagesize = _this.pageSize;
      params.key = _this.key;
      var link = url.getSys("getlist_role");
      $.get(link, params, data => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    //搜索查询
    selList: function() {
      this.currentPage4 = 1;
      this.getList();
    },
    //提交表单
    submitForm(formName) {
      var _this = this;
      var link = url.getSys("addorupdate_role");
      var info = this.form;
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submiting = true;
          //添加或者修改
          $.post(link, info, res => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    //编辑
    editClick: function(row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    //删除
    delClick: function(row) {
      var _this = this;
      var link = url.getSys("delete_role"); //获取请求地址
      var id = row.id; //获取对象id

      this.$confirm("确定要删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          $.post(link, { idList: id }, res => {
            _this.btnLoading = false;
            if (res.status) {
              _this.msg = res;
              _this.getList();
            }
          });
          this.$message({
            type: "success",
            message: "删除成功!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //获取置顶角色的权限tree
    getRoleTree(roleid) {
      var _this = this;
      _this.roleid = roleid;
      var link = url.getSys("getnavtree");
      $.get(link, { roleid: roleid }, data => {
        _this.treeData = data;
        _this.checkedKeys = [];
        for (var i = 0; i < data.length; i++) {
          var item = data[i];
          var children = item.children;
          for (var j = 0; j < children.length; j++) {
            var last = children[j];
            if (last.checked) {
              _this.checkedKeys.push(last.id);
            }
          }
        }
      });
    },
    //重置操作
    resetRoleTree() {
      var _this = this;
      var keys = _this.$refs.tree
        .getCheckedKeys()
        .concat(_this.$refs.tree.getHalfCheckedKeys());
      var link = url.getSys("ResetNav");
      $.post(link, { roleid: _this.roleid, navStr: keys.toString() }, res => {
        if (res.status) {
          _this.$message({
            type: "success",
            message: res.msg
          });
          _this.roleVisible = false;
        } else {
          _this.$message({
            type: "error",
            message: res.msg
          });
        }
      });
    }
  },
  mounted() {
    //获取服务器列表
    this.getList();
  }
};
</script>

<style>
.query-form {
  margin-top: 20px;
  padding-top: 25px;
  background: #f2f2f2;
}
.el-form-item--small.el-form-item {
  margin-right: 0px;
}
.el-button--primary {
  margin-left: 10px;
}
</style>
